.App {
  display: flex;
  flex-direction: row;
  height: 100vh;
}

.side-bar {
  display: flex;
  flex-direction: column;
  width: 15vw;
  height: 100vh;  
  box-shadow: 0 2px 5px rgba(0,0,0,0.1), 0 2px 5px rgba(0,0,0,0.2);
}

.side-bar-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  color: whitesmoke;
  background-color: #2d383a;
  border-bottom: 5px solid crimson;
}

.side-bar-header h1 {
  margin: 0;
  font-size: 36pt;
}

.side-bar-body {
  padding-left: 10px;
  margin-bottom: 10px;
  overflow-y: scroll;
}
.searchbox input {
  width: 85%;
  margin: 10px;
  border-radius: 10px;
  border: 1px solid #777;
  height: 20px;
  padding: 5px;
}
.api-link {
  margin-top: 10px;
  font-size: 12pt;
  transition: all .1s ease;
  padding-left: 10px;
}

.api-link:hover {
  cursor: pointer;
  border-left: 5px solid crimson;
  transition: all .1s ease;
}

#api-data {
  width: 90vw;
  padding-left: 30px;
  padding-top: 30px;
  overflow-y: scroll;
}